<template>
  <div :class="cardClass" v-if="rendersCard">
    <span class="csn-club-card-header">{{ cardInfo.cardName }}</span>
    <span class="csn-club-card-subheader">{{ cardInfo.cardLevel }}</span>
    <span class="csn-club-card-holder-name">{{ cardInfo.userName }}</span>
    <div class="csn-club-card-holder-data">
      <span>{{ cardInfo.cardId }}</span>
      <span>{{ cardInfo.cardExpiration }}</span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  CLUB_CARD,
  Module,
  CASINO_INFO,
  CASINO_FIVE,
  clubLevel,
} from '@/constants'
import { isEmptyOrNil } from '@/helpers'
import * as CasinoInfo from '@/models/getters/casinoInfo'

export default {
  name: CLUB_CARD,
  props: {
    level: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    ...mapState(Module.CASINO_INFO, [CASINO_INFO]),
    CasinoInfo: () => CasinoInfo,
    t() {
      return this.$createComponentTranslator(CLUB_CARD)
    },
    cardLevel() {
      return this.level || CasinoInfo.getCardLevel(this.CASINO_INFO)
    },
    cardInfo() {
      const firstName = CasinoInfo.getFirstName(this.CASINO_INFO)
      const lastName = CasinoInfo.getLastName(this.CASINO_INFO)
      const cardId = CasinoInfo.getCardId(this.CASINO_INFO)
      const cardExpiryDate = CasinoInfo.getCardExpiryDate(this.CASINO_INFO)

      return {
        cardName: CasinoInfo.getCardName(this.CASINO_INFO),
        cardLevel: this.cardLevel,
        userName: `${firstName} ${lastName}`,
        cardId: `${this.t('id')} ${cardId}`,
        cardExpiration: `${this.t('since')} ${cardExpiryDate}`,
      }
    },
    rendersCard() {
      return !isEmptyOrNil(this.CASINO_INFO)
    },
    cardClass() {
      const isFive = process.env.VUE_APP_THEME === CASINO_FIVE

      return [
        'csn-club-card',
        `csn-club-card-${clubLevel[this.cardLevel]}`,
        isFive && 'csn-club-card-five',
      ]
    },
  },
}
</script>
